import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, CardTitle } from 'reactstrap'

import type { TenantHourlyWorkData, TenantHourlyWorkRow } from 'api/dashboard'

import { selectDashboardStatus, getTenantSummary, getWorkerCounts } from 'slices/dashboardSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { clearErrorMessage, selectUsersStatus, updateDisplayFilter } from 'slices/usersSlice'

import { BadgeButton, Chart, CustomButton, PivotItem, PivotOuterIndex, NotSelectedPlaceholder } from 'components/common'
import type { BadgeItem, ConnectionType, Series } from 'components/common/types'
import {
  createLineChartOptions,
  createBarChartOptions,
  getMaxYAxisValue,
  connectionTypes,
  PlaceholderTypes,
} from 'components/common/utils'

import useDashboard from 'hooks/useDashboard'
import useDateQuery from 'hooks/useDateQuery'

import Dashboard from './Dashboard'
import GraphSelectButton from './GraphSelectButton'
import WorkerCountsCard from './WorkerCountsCard'
import { calculateProductivityMetrics, colorTypeToCode, createXAxis } from './utils'

import styles from './TotalSummary.module.scss'
const graphTypes = {
  Line: 'line',
  Bar: 'bar',
} as const

const graphList = [
  { header: '累計グラフ', icon: 'amount', key: 'total', type: graphTypes.Line },
  { header: '時間別グラフ', icon: 'by_time', key: 'time', type: graphTypes.Line },
  { header: '目標達成率', icon: 'percent', key: 'rate', type: graphTypes.Line },
  { header: '過不足人時', icon: 'replace', key: 'forecastShortfallHour', type: graphTypes.Bar },
]

// createRows用のnullを含む足し算. aもbもnullのときにはnullを返す
const nullAddition = (a: number | null, b: number | null) => {
  if (a === null && b === null) {
    return null
  }
  return (a || 0) + (b || 0)
}
const createRows = (data: TenantHourlyWorkData | undefined, graphPivotIndex: number): TenantHourlyWorkRow[] => {
  if (!data) {
    return []
  }
  if (graphPivotIndex === 0 || graphPivotIndex === 2) {
    return data.data.map(d => ({ ...d, time: moment(d.time).format() }))
  }
  return data.data.reduce<TenantHourlyWorkRow[]>((acc, cur) => {
    // 15, 30, 45 分を切り捨てた時刻
    const flooredTime = moment(cur.time).startOf('hour').utc().format()

    // 正時のデータに集約する
    const target = acc.find(t => t.time === flooredTime)
    if (!target) {
      return acc.concat({ ...cur, time: flooredTime })
    }
    target.planCount = target.planCount + cur.planCount
    target.recordCount = nullAddition(target.recordCount, cur.recordCount)
    return acc
  }, [])
}

type SummaryBadgeItem = BadgeItem & {
  unit: string
  connectionType: ConnectionType
}

const TotalSummary: React.FC = () => {
  const dispatch = useDispatch()
  const [graphPivotIndex, setGraphPivotIndex] = React.useState(0)
  const [submitted, setSubmitted] = React.useState(false)
  const [selectedGraphs, setSelectedGraphs] = React.useState<number[]>([])

  const navigate = useNavigate()

  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)
  const { tenantSummary } = useSelector(selectDashboardStatus, shallowEqual)
  const { displayFilter, errorMessage, isRequesting } = useSelector(selectUsersStatus, shallowEqual)

  const date = useDateQuery()
  const { onDateChange } = useDashboard()

  const graphBadges = React.useMemo(
    () =>
      _.sortBy(tenantSummary?.hourlyWorkData, 'scheduleTypeId')
        .filter(data => selectedGraphs.includes(data.scheduleTypeId))
        .map<SummaryBadgeItem>(data => ({
          color: data.scheduleTypeColor,
          key: data.scheduleTypeId,
          label: `${data.workspaceName}/${data.scheduleTypeName}`,
          unit: data.unit,
          connectionType: data.connectionType,
          disabled: true,
        })),
    [selectedGraphs, tenantSummary?.hourlyWorkData]
  )

  React.useEffect(() => {
    dispatch(getTenantSummary(date, { dashboardFilter: true }))
  }, [date, dispatch])

  React.useEffect(() => {
    dispatch(getWorkerCounts(date))
  }, [dispatch, date])

  React.useEffect(() => {
    if (!displayFilter?.dashboard) {
      return
    }
    setSelectedGraphs(
      displayFilter.dashboard.workspaceData
        .flatMap(df => df.scheduleTypeData)
        .filter(scheduleType => scheduleType.isFilteredInSummary)
        .map(scheduleType => scheduleType.id)
    )
  }, [displayFilter, setSelectedGraphs, date])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const selectedBadges = React.useMemo(
    () => graphBadges.filter(badge => selectedGraphs.some(key => key === badge.key)),
    [graphBadges, selectedGraphs]
  )
  const existTargetAchievementRateGraph = React.useMemo(() => {
    if (!tenantSummary) {
      return false
    }

    const selectedBadgeKeys = selectedBadges.map(badge => badge.key)
    const targetCountData = tenantSummary.workspaceData
      .flatMap(({ data }) => data)
      .filter(data => selectedBadgeKeys.includes(data.scheduleTypeId))

    return tenantSummary.hourlyWorkData
      .filter(
        data =>
          selectedBadgeKeys.includes(data.scheduleTypeId) &&
          targetCountData.find(tcData => data.scheduleTypeId === tcData.scheduleTypeId)?.targetCount
      )
      .some(hwData => hwData.data.some(data => data.cumulativePlanCount || data.cumulativeRecordCount))
  }, [tenantSummary, selectedBadges])

  const barChartOptions = React.useMemo(() => {
    const barGraphData =
      _.chain(tenantSummary?.workspaceData)
        .flatMap(w => w.data)
        .filter(s => selectedBadges.some(badge => badge.key === s.scheduleTypeId))
        .map(data => {
          const { forecastShortfallHour } = calculateProductivityMetrics(data)
          const label = selectedBadges.find(badge => badge.key === data.scheduleTypeId)?.label || ''
          return { data: forecastShortfallHour, label }
        })
        .partition(o => !o.data) //値が存在するかで分割
        .valueOf()
        .map(g => _.sortBy(g, 'data'))
        .flatMap(g => g) || []
    const handleGraphClick = (label: string) => {
      // barGraphDataでlabelにselectedBadges.labelを渡しているのでselectedBadgesからscheduleTypeIdを取得する
      const selectedScheduleTypeId = selectedBadges.find(badge => badge.label === label)?.key
      const selectedWorkspaceId = tenantSummary?.workspaceData.find(data =>
        data.data.some(d => d.scheduleTypeId === selectedScheduleTypeId)
      )?.workspaceId
      if (selectedWorkspaceId) {
        navigate(`/dashboard/${selectedWorkspaceId}`)
      }
    }

    const options = createBarChartOptions(barGraphData, handleGraphClick)
    options.tooltip!.formatter = function () {
      return `
          <div style="text-align:center">${this.x}<br>${(this.y || 0) < 0 ? this.y : '+' + this.y}人時</div>`
    }
    return options
  }, [tenantSummary, selectedBadges, navigate])

  const lineChartOptions = React.useMemo(() => {
    if (!tenant || !tenantSummary) {
      return {}
    }

    const workspaceDataArray = tenantSummary ? tenantSummary.workspaceData : []
    const hourlyWorkDataArray = tenantSummary ? tenantSummary.hourlyWorkData : []

    const xAxisData = createXAxis(tenant.businessStartTime, tenant.businessEndTime, graphPivotIndex !== 1, date)
    const currentTime = moment()
    const currentTimeIndex = xAxisData.findIndex(x => moment(x).isAfter(currentTime)) - 1
    const isBusinessTime = currentTimeIndex >= -1

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)
      const hourlyWorkData = hourlyWorkDataArray.find(data => data.scheduleTypeId === graphBadge.key)
      const rows = createRows(hourlyWorkData, graphPivotIndex)

      const targetCount =
        workspaceDataArray.flatMap(({ data }) => data).find(data => data.scheduleTypeId === graphBadge.key)
          ?.targetCount || null

      const unit = graphPivotIndex === 2 ? '%' : graphBadge.unit ?? ''

      const lastTargetIndex = _.findLastIndex(xAxisData, time => {
        const target = _.findLast(rows, row => {
          return row && !_.isNull(row.recordCount) && row.recordCount > 0
        })
        if (!target) {
          return false
        }
        return new Date(target.time).getTime() === new Date(time).getTime()
      })
      // areaの上にlineを表示するためにareaを先にしておく
      const recordData = xAxisData.map((time, index) => {
        // 現在時刻以降のデータは表示しない
        // 手動実績の場合は現在時刻以降にもデータが存在する場合のみ表示する
        if (moment(date).isSameOrAfter(currentTime, 'day') && isBusinessTime && index > currentTimeIndex) {
          if (graphBadge.connectionType !== connectionTypes.Manual) {
            return null
          }
          if (graphPivotIndex === 1 && index > lastTargetIndex) {
            return null
          }
          const beforeTarget = rows.find(row => {
            const oneHourIndex = 4
            return (
              index > oneHourIndex &&
              new Date(row.time).getTime() === new Date(xAxisData[index - oneHourIndex]).getTime()
            )
          })
          if (beforeTarget && beforeTarget.recordCount === 0) {
            return null
          }
        }

        // パフォーマンス改善のために moment でなく new Date を使う
        const target = rows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        if (!target) {
          return null
        }
        if (graphPivotIndex === 0) {
          return target.cumulativeRecordCount
        }
        if (graphPivotIndex === 1) {
          return target.recordCount
        }
        return target.cumulativeRecordCount && targetCount && (target.cumulativeRecordCount * 100) / targetCount
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: recordData,
        name: graphBadge.label,
        custom: {
          unit,
        },
      })

      const planData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = rows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        if (!target) {
          return null
        }
        if (graphPivotIndex === 0) {
          return target.cumulativePlanCount
        }
        if (graphPivotIndex === 1) {
          return target.planCount
        }
        return target.cumulativePlanCount && targetCount && (target.cumulativePlanCount * 100) / targetCount
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: planData,
        name: graphBadge.label,
        custom: {
          unit,
        },
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    _.merge(options, {
      xAxis: {
        labels: {
          step: graphPivotIndex === 1 ? 2 : 8,
        },
        tickInterval: 1,
      },
    })
    //目標達成率が選択されているとき、100の基準線を表示
    if (graphPivotIndex === 2) {
      const maxValue = getMaxYAxisValue(yAxis)
      // 100だとラベルが見切れるため、110で設定する
      const defaultMaxValue = 110
      _.merge(options, {
        yAxis: {
          plotLines: [
            {
              value: 100,
              color: 'var(--bs-primary)',
              zIndex: 1,
              width: 1,
              label: {
                text: '100%',
                align: 'left',
                y: -5,
                x: 0,
                style: {
                  color: 'var(--bs-primary)',
                },
              },
            },
          ],
          max: maxValue < defaultMaxValue ? defaultMaxValue : maxValue,
        },
      })
    }
    options.tooltip!.formatter = function () {
      return (
        '<div style="text-align:center">' +
        [
          graphPivotIndex === 1
            ? `${moment(this.x).format('HH:mm')}~${moment(this.x).add(1, 'h').format('HH:mm')}`
            : moment(this.x).format('HH:mm'),
          this.series.name,
          `${Math.floor(this.y || 0)}${this.series.options.custom!.unit}`,
        ].join('<br>') +
        '</div>'
      )
    }

    return options
  }, [tenant, tenantSummary, graphPivotIndex, selectedBadges, date])

  const lastImportedAt = React.useMemo(() => {
    const importedAt = tenantSummary?.hourlyWorkData[0]?.lastImportedAt
    return importedAt && moment(importedAt).format('YYYY/MM/DD HH:mm:ss')
  }, [tenantSummary])

  const onClickGraphSaveButton = () => {
    if (!displayFilter?.dashboard) {
      dispatch(showError())
      return
    }
    setSubmitted(true)
    const workspaceData = displayFilter.dashboard.workspaceData.map(df => {
      const scheduleTypeData = df.scheduleTypeData.map(scheduleType => ({
        ...scheduleType,
        isFilteredInSummary: selectedGraphs.includes(scheduleType.id),
      }))
      return { id: df.id, scheduleTypeData }
    })

    dispatch(updateDisplayFilter({ dashboard: { workspaceData } }))
  }

  const graphItems = React.useMemo(
    () =>
      _.chain(tenantSummary?.workspaceData)
        .filter(o => !_.isEmpty(o.data))
        .map(data => {
          const filterItems = data.data.map(s => ({
            key: s.scheduleTypeId,
            label: s.scheduleTypeName,
            checked: selectedGraphs.includes(s.scheduleTypeId),
          }))
          return {
            label: data.workspaceName,
            filterItems,
          }
        })
        .sortBy(o => o.label)
        .value(),
    [tenantSummary, selectedGraphs]
  )

  const disabled = React.useMemo(() => _.isEmpty(tenantSummary?.workspaceData), [tenantSummary])

  const handleChangeSelectedGraphs = (filterItems: number[]) => {
    const addiction = filterItems.filter(s => !selectedGraphs.includes(s))
    const existAddiction = !_.isEmpty(addiction)
    if (existAddiction) {
      addiction.forEach(scheduleTypeId => dispatch(getTenantSummary(date, { scheduleTypeId })))
    }
    setSelectedGraphs(filterItems)
  }

  const chartOptions = React.useMemo(
    () => (graphList[graphPivotIndex].type === graphTypes.Bar ? barChartOptions : lineChartOptions),
    [graphPivotIndex, barChartOptions, lineChartOptions]
  )

  const isPast = React.useMemo(() => dayjs(date).isBefore(dayjs(), 'day'), [date])

  return (
    <Dashboard
      onInterval={() => selectedGraphs.forEach(scheduleTypeId => dispatch(getTenantSummary(date, { scheduleTypeId })))}
      onDateChange={onDateChange}
    >
      <Card>
        <CardBody>
          <div className="d-flex">
            <CardTitle className="fw-bold text-nowrap">作業進捗</CardTitle>
            <div className="w-100 d-flex justify-content-end">
              <GraphSelectButton
                items={graphItems}
                selectedGraphs={selectedGraphs}
                onChange={filterItems => handleChangeSelectedGraphs(filterItems)}
                disabled={disabled}
              />
              <CustomButton
                outline
                icon="save"
                size="sm"
                className="pe-2"
                onClick={() => onClickGraphSaveButton()}
                disabled={disabled || isPast}
              >
                表示作業の保存
              </CustomButton>
            </div>
          </div>
          {_.isEmpty(selectedGraphs) ? (
            <NotSelectedPlaceholder type={PlaceholderTypes.dashboard} />
          ) : (
            <div>
              <PivotOuterIndex selectedIndex={graphPivotIndex} onChange={setGraphPivotIndex}>
                {graphList.map(({ header, icon, key }) => (
                  <PivotItem headerText={header} icon={icon} key={key} />
                ))}
              </PivotOuterIndex>
              {graphPivotIndex === 2 && !existTargetAchievementRateGraph ? (
                <div className={`d-flex flex-column justify-content-center align-items-center ${styles.noDataView}`}>
                  <div className="fw-bold font-large mb-3">目標が設定されていません</div>
                  <div>目標を設定すると、目標を100%としたグラフを表示することができます。</div>
                </div>
              ) : (
                <Chart options={chartOptions} />
              )}
            </div>
          )}
        </CardBody>

        <CardBody className="d-flex row p-2 mx-0">
          <BadgeButton items={graphBadges} />
        </CardBody>

        <CardBody className="d-flex text-muted align-self-end pt-0">
          {lastImportedAt && <i className="icf-updated align-self-center pe-1" />}
          <small>{lastImportedAt}</small>
        </CardBody>
      </Card>
      <WorkerCountsCard close total />
    </Dashboard>
  )
}

export default TotalSummary
