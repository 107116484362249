import dayjs from 'dayjs'
import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import { selectDashboardStatus, getTenantSummary, getWorkspaceSummary, getWorkerCounts } from 'slices/dashboardSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getPlanByDate } from 'slices/plansSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { clearErrorMessage, selectUsersStatus, updateDisplayFilter } from 'slices/usersSlice'
import { checkWorkspaceExists, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { PivotItem, PivotOuterIndex, CustomButton, FilteringButton, NotSelectedPlaceholder } from 'components/common'
import type { ColorType } from 'components/common/types'
import { connectionTypes, PlaceholderTypes } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import useDateQuery from 'hooks/useDateQuery'

import Dashboard from './Dashboard'
import PerformanceGraph from './PerformanceGraph'
import PerformanceList from './PerformanceList'
import ScheduleTypeProgressCard from './ScheduleTypeProgressCard'
import WorkerCountsCard from './WorkerCountsCard'
import WorkspaceProgressCards from './WorkspaceProgressCards'

const IndividualSummary: React.FC = () => {
  const [submitted, setSubmitted] = React.useState(false)
  const [selectedCards, setSelectedCards] = React.useState<number[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const { workspaceSummary, tenantSummary } = useSelector(selectDashboardStatus, shallowEqual)
  const { displayFilter, isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const workspaceStatus = useSelector(selectWorkspacesStatus, shallowEqual)

  const { businessStartTime, businessEndTime } = useBusinessTime()
  const date = useDateQuery()

  React.useEffect(() => {
    // 選択中のワークスペースが削除されているか確認
    dispatch(checkWorkspaceExists(workspaceId))
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    // シフトが存在しない場合、移動の項目から作業計画を消す必要があるため、エラー時にworksを空にする
    dispatch(getPlanByDate(Number(workspaceId), date))
    dispatch(getTenantSummary(date, { dashboardFilter: true, workspaceId: Number(workspaceId) }))
    dispatch(getWorkspaceSummary(workspaceId, date))
    dispatch(getWorkerCounts(date, workspaceId))
  }, [date, dispatch, workspaceId])

  const scheduleTypeProgresses = React.useMemo(() => {
    if (!tenantSummary) {
      return []
    }
    const workspaceData = tenantSummary.workspaceData.find(w => w.workspaceId === workspaceId)
    if (!workspaceData) {
      return []
    }

    const colors: { [key: number]: ColorType } = tenantSummary.hourlyWorkData.reduce((acc, value) => {
      return { ...acc, [value.scheduleTypeId]: value.scheduleTypeColor }
    }, {})
    return _.sortBy(workspaceData.data, 'scheduleTypeId')
      .filter(row => selectedCards.includes(row.scheduleTypeId))
      .map(row => ({
        ...row,
        scheduleTypeColor: colors[row.scheduleTypeId],
      }))
  }, [tenantSummary, workspaceId, selectedCards])

  const relatedWorkspaceProgresses = React.useMemo(() => {
    if (!tenantSummary || !workspaceSummary) {
      return []
    }

    const relatedWorkspaceIds = workspaceSummary.relatedWorkspaceData.map(w => w.workspaceId)
    return tenantSummary.workspaceData
      .filter(data => relatedWorkspaceIds.includes(data.workspaceId))
      .map(data => ({
        workspaceId: data.workspaceId,
        workspaceName: data.workspaceName,
        lastImportedAt: data.data?.[0]?.lastImportedAt,
        scheduleTypeProgresses: data.data.map(row => ({
          scheduleTypeName: row.scheduleTypeName,
          targetCount: row.targetCount,
          planningHour: row.planningHour,
          todayPlanningHour: row.todayPlanningHour,
          planCount: row.planCount,
          recordCount: row.recordCount,
        })),
      }))
  }, [tenantSummary, workspaceSummary])

  const [pivotIndex, setPivotIndex] = React.useState(0)
  const { pathname } = useLocation()
  React.useEffect(() => {
    const nextIndex = /^\/dashboard\/\d+\/performance-list$/.test(pathname)
      ? 1
      : /^\/dashboard\/\d+\/performance-graph\//.test(pathname)
        ? 2
        : /^\/dashboard\/\d+\/workspace$/.test(pathname)
          ? 3
          : 0
    setPivotIndex(nextIndex)
  }, [pathname])
  const onPivotChange = (index: number) => {
    // pivotの選択状態が変化したらpivotIndexを直接変更するのではなく、url遷移の副作用として変更する。
    const nextPath =
      index === 1
        ? `/dashboard/${workspaceId}/performance-list`
        : index === 2
          ? `/dashboard/${workspaceId}/performance-graph/workspace`
          : index === 3
            ? `/dashboard/${workspaceId}/workspace`
            : `/dashboard/${workspaceId}`
    if (pathname !== nextPath) {
      const dateQuery = date ? `?date=${date}` : ''
      navigate(nextPath + dateQuery)
    }
  }
  const onClickGraphSaveButton = () => {
    if (!displayFilter?.dashboard) {
      dispatch(showError())
      return
    }
    setSubmitted(true)
    const updateWorkspaces = displayFilter.dashboard.workspaceData.map(df => {
      if (df.id !== workspaceId) {
        return df
      }
      const scheduleTypeData = df.scheduleTypeData.map(scheduleType => ({
        ...scheduleType,
        isFilteredInEachWorkspace: selectedCards.includes(scheduleType.id),
      }))
      return { id: workspaceId, scheduleTypeData }
    })
    dispatch(updateDisplayFilter({ dashboard: { workspaceData: updateWorkspaces } }))
  }

  const filterItems = React.useMemo(
    () =>
      tenantSummary?.workspaceData
        .filter(o => o.workspaceId === workspaceId)
        .flatMap(o => o.data)
        .map(data => ({
          key: data.scheduleTypeId,
          label: data.scheduleTypeName,
          checked: selectedCards.includes(data.scheduleTypeId),
        })) || [],
    [tenantSummary?.workspaceData, selectedCards, workspaceId]
  )

  const viewProgressCardSelectButton = React.useMemo(
    () => !_.isEmpty(tenantSummary?.workspaceData.filter(o => o.workspaceId === workspaceId)),
    [tenantSummary, workspaceId]
  )

  React.useEffect(() => {
    if (!displayFilter?.dashboard) {
      return
    }

    const initSelectedCards = displayFilter?.dashboard.workspaceData
      .flatMap(df => (df.id === workspaceId ? df.scheduleTypeData : []))
      .filter(scheduleType => scheduleType.isFilteredInEachWorkspace)
      .map(scheduleType => scheduleType.id)
    setSelectedCards(initSelectedCards)
  }, [displayFilter, workspaceId, setSelectedCards, date])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])
  const handleChangeSelectedCards = (items: number[]) => {
    setSelectedCards(items)
    const diff = items.filter(s => !selectedCards.includes(s))

    if (_.isEmpty(diff)) {
      return
    }
    diff.forEach(scheduleTypeId => dispatch(getTenantSummary(date, { scheduleTypeId })))
  }

  const tabs = React.useMemo(() => {
    const tabContents = [{ header: '作業別配置人数一覧', key: 'work' }]

    const connectionTypeAutoExists = partialScheduleTypes.some(type => type.connectionType === connectionTypes.Auto)

    if (connectionTypeAutoExists) {
      tabContents.push({ header: 'パフォーマンスリスト', key: 'list' })
      tabContents.push({ header: 'パフォーマンスグラフ', key: 'graph' })
    }

    return tabContents.concat({ header: '連携ワークスペース', key: 'workspace' })
  }, [partialScheduleTypes])

  const showProgressCardField = React.useMemo(
    () => partialScheduleTypes.some(type => type.connectionType !== connectionTypes.None),
    [partialScheduleTypes]
  )

  const onDateChange = React.useCallback(
    (newDate: string) => {
      const callback = (workspaceExists: boolean) => {
        if (workspaceExists) {
          navigate(`${pathname}?date=${newDate}`)
        } else {
          dispatch(showError({ errorMessage: '指定された日付のワークスペースが存在しません' }))
        }
      }
      dispatch(checkWorkspaceExists(workspaceId, newDate, callback))
    },
    [dispatch, workspaceId, navigate, pathname]
  )

  const isPast = React.useMemo(() => dayjs(date).isBefore(dayjs(), 'day'), [date])

  return (
    <Dashboard
      onInterval={() => {
        selectedCards.forEach(scheduleTypeId => dispatch(getTenantSummary(date, { scheduleTypeId })))
        dispatch(getWorkspaceSummary(workspaceId, date))
      }}
      onDateChange={onDateChange}
    >
      {showProgressCardField && (
        <>
          {viewProgressCardSelectButton && (
            <div className="d-flex justify-content-end pb-2">
              <div className="me-2">
                <FilteringButton
                  items={filterItems}
                  value={selectedCards}
                  onChange={items => handleChangeSelectedCards(items)}
                  label="表示作業の選択"
                  size="sm"
                ></FilteringButton>
              </div>

              <CustomButton
                outline
                icon="save"
                size="sm"
                disabled={!workspaceStatus.workspaceExists || isPast}
                onClick={() => onClickGraphSaveButton()}
              >
                表示作業の保存
              </CustomButton>
            </div>
          )}

          {_.isEmpty(scheduleTypeProgresses) ? (
            viewProgressCardSelectButton && <NotSelectedPlaceholder type={PlaceholderTypes.dashboard} />
          ) : (
            <Row md={3} className="mb-3 ps-3">
              {scheduleTypeProgresses.map((scheduleTypeProgress, index) => (
                <Col key={`individual-col-${index}`} className="p-0">
                  <ScheduleTypeProgressCard scheduleTypeProgress={scheduleTypeProgress} />
                </Col>
              ))}
            </Row>
          )}
        </>
      )}

      <PivotOuterIndex selectedIndex={pivotIndex} onChange={onPivotChange}>
        {tabs.map(({ header, key }) => (
          <PivotItem headerText={header} key={key}>
            {key === 'work' ? (
              <WorkerCountsCard />
            ) : key === 'list' ? (
              <PerformanceList workspaceId={workspaceId} workspaceSummaryData={workspaceSummary?.workspaceData} />
            ) : key === 'graph' ? (
              <PerformanceGraph
                workspaceId={workspaceId}
                workspaceSummaryData={workspaceSummary?.workspaceData}
                businessStartTime={businessStartTime}
                businessEndTime={businessEndTime}
              />
            ) : key === 'workspace' ? (
              <WorkspaceProgressCards workspaceProgresses={relatedWorkspaceProgresses} />
            ) : (
              <div>{header}のコンテンツ</div>
            )}
          </PivotItem>
        ))}
      </PivotOuterIndex>
    </Dashboard>
  )
}

export default IndividualSummary
